import React from 'react';
import styled from 'styled-components'
import { Link } from "gatsby"
import { Grid, GridItem, Typography, Box, Bullet } from '@mintuz/marvel'
import css from '@styled-system/css'
import { Focus2 as Focus } from '@styled-icons/remix-line/Focus2'
import { Medal } from '@styled-icons/remix-line/Medal'
import { Group } from '@styled-icons/material-rounded/Group'
import { Calculate } from '@styled-icons/material-rounded/Calculate'
import { Heart } from '@styled-icons/boxicons-regular/Heart'
import { Numbers } from '@styled-icons/remix-fill/Numbers'
import { Sync } from '@styled-icons/boxicons-regular/Sync'
import { Notes } from '@styled-icons/material-outlined/Notes'
import { UserFriends as Friend } from '@styled-icons/fa-solid/UserFriends'
import { Community } from '@styled-icons/remix-line/Community'
import { CallReceived } from '@styled-icons/material-twotone/CallReceived'
import { SEO } from '../components/seo';
import { HeaderStyled, HeaderContentStyled } from '../components/HeaderStyled';
import { Layout } from '../components/layout';
import { Logo } from '../components/Logo';
import { GhostButton } from '../components/GhostButton'
import { AppStoreLink } from '../components/AppStoreLink'
import { AppleHealth } from '../assets/AppleHealth'
import SegmentOneImage from '../assets/Segment_One_New.png'
import SegmentTwoImage from '../assets/Segment_Two.png'

const GridWrap = styled.div`
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
`

const HeaderCTAList = styled(Box)`
    display: flex;
    justify-content: center;
`

const SegmentOne = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
`

const SegmentTwo = styled.div`
    position: relative;
    margin-top: 100px;
    padding-top: 50px;
    padding-bottom: 50px;

    ${css({
        backgroundColor: 'lightGrey',
    })}

    &:after {
        position: absolute;
        top: -99px;
        right: 0;
        left: 0;
        content: ' ';
        height: 100px;
        clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);

        ${css({
            backgroundColor: 'lightGrey',
        })}
    }
`

const PhoneImage = styled(Box)``

const Heading = styled(Typography)`
    position: relative;
`

function Index() {
    return (
        <Layout>
            <SEO />
            <HeaderStyled>
                <GridWrap>
                    <Grid>
                        <GridItem width={[1 / 1]}>
                            <Box pt={[[4]]} display="flex" justifyContent="space-between" alignItems="center">
                                <Link to="/"><Logo /></Link>
                                <GhostButton as={Link} to="/workouts" variant="secondary">Free Workouts</GhostButton>
                            </Box>
                        </GridItem>
                        <GridItem width={[1 / 1]}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                                <HeaderContentStyled color="white">
                                    <Typography as="h1" variant="displayOne" mb={[3]}>Workout Plan Bot App</Typography>
                                    <Typography as="p" variant="displayThree">A simple workout tracker designed to keep you focused on your fitness goals.</Typography>
                                    <HeaderCTAList mt={[6]}>
                                        <AppStoreLink />
                                        <Box ml={[4]}>
                                            <AppleHealth />
                                        </Box>
                                    </HeaderCTAList>
                                </HeaderContentStyled>
                            </Box>
                        </GridItem>
                    </Grid>
                </GridWrap>
            </HeaderStyled>
            <SegmentOne>
                <GridWrap>
                    <Grid>
                        <GridItem width={{default: 1/1, md: 3/6}}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <div>
                                    <Heading as="h2" variant="displayTwo" mb={[6]}>Designed to make your fitness journey easy</Heading>
                                    <Bullet>
                                        <Box color="purple"><Focus size="36" /></Box>
                                        <Typography as="p" variant="displayThree" mb={[6]}>Less thinking, more lifting. the app has a simple to use interface that gets out of the way so you can focus on your workout.</Typography>
                                    </Bullet>
                                    <Bullet>
                                        <Box color="purple"><Medal size="36"/></Box>
                                        <Typography as="p" variant="displayThree" mb={[6]}>Set and keep track of personal bests. The app will keep you motivated by visualising your progression over time with charts.</Typography>
                                    </Bullet>
                                    <Bullet>
                                        <Box color="purple"><Numbers size="36"/></Box>
                                        <Typography as="p" variant="displayThree" mb={[6]}>Don't like math? That's fine, the app calculates 1RM and max volume for you.</Typography>
                                    </Bullet>
                                    <Bullet>
                                        <Box color="purple"><Heart size="36" /></Box>
                                        <Typography as="p" variant="displayThree" mb={[6]}>Syncs to Apple Health so you don't miss out on completing your activity rings.</Typography>
                                    </Bullet>
                                    <Bullet>
                                        <Box color="purple"><Sync size="36" /></Box>
                                        <Typography as="p" variant="displayThree" mb={[6]}>Support for iCloud so all your Apple devices can access your data for peace of mind.</Typography>
                                    </Bullet>
                                    <Bullet>
                                        <Box color="purple"><Notes size="36" /></Box>
                                        <Typography as="p" variant="displayThree" mb={[6]}>Workout Notes, record how you are feeling in the moment.</Typography>
                                    </Bullet>
                                    <Bullet>
                                        <Box color="purple"><Calculate size="36"/></Box>
                                        <Typography as="p" variant="displayThree" mb={[0]}>Built in plate calculator, quickly add plates to your Barbell.</Typography>
                                    </Bullet>
                                </div>
                            </Box>
                        </GridItem>
                        <GridItem width={{default: 1/1, md: 1/6}} />
                        <GridItem width={{default: 1/1, md: 2/6}}>
                            <Box mt={{default: 0, sm: 6}} display="flex" justifyContent="center">
                                <div>
                                    <PhoneImage
                                        as="img"
                                        width="100%"
                                        maxWidth="320px"
                                        src={SegmentOneImage}
                                        alt="picture of workout plan bot workout tracking screen within iOS Device."
                                    />
                                </div>
                            </Box>
                        </GridItem>
                    </Grid>
                </GridWrap>
            </SegmentOne>
            <SegmentTwo>
                <GridWrap>
                    <Grid>
                        <GridItem width={{default: 1/1, md: 2/6}}>
                                <Box mb={{default: 6, md: 0}} display="flex" justifyContent="center">
                                    <div>
                                        <PhoneImage
                                            as="img"
                                            width="100%"
                                            maxWidth={{default:"320px", md: "none"}}
                                            src={SegmentTwoImage}
                                            alt="picture of workout plan bot workout tracking statistics screen within iOS Device."
                                        />
                                    </div>
                                </Box>
                        </GridItem>
                        <GridItem width={{default: 1/1, md: 1/6}} />
                        <GridItem width={{default: 1/1, md: 3/6}}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <div>
                                    <Heading as="h2" variant="displayTwo" mb={[6]}>Share your workouts</Heading>
                                    <Bullet>
                                        <Box color="purple"><Friend size="36" /></Box>
                                        <Typography as="p" variant="displayThree" mb={[6]} mt={[0]}>Share your workouts with friends.</Typography>
                                    </Bullet>
                                    <Bullet>
                                        <Box color="purple"><CallReceived size="36" /></Box>
                                        <Typography as="p" variant="displayThree" mb={[6]} mt={[0]}>Receive routines from your personal trainer.</Typography>
                                    </Bullet>
                                    <Bullet>
                                        <Box color="purple"><Community size="36" /></Box>
                                        <Typography as="p" variant="displayThree" mb={[6]} mt={[0]}>Access routines created by our community.</Typography>
                                    </Bullet>
                                    <Bullet>
                                        <Box color="purple"><Group size="36" /></Box>
                                        <Typography as="p" variant="displayThree" mb={[0]} mt={[0]}>Group Workouts, Workout with friends over FaceTime and cheer each other on with Apple SharePlay support.</Typography>
                                    </Bullet>
                                </div>
                            </Box>
                        </GridItem>
                    </Grid>
                </GridWrap>
            </SegmentTwo>
            <SegmentOne>
                <GridWrap>
                    <Grid>
                        <GridItem width={{default: 1/1}}>
                            <Box textAlign="center">
                                <Heading as="h2" variant="displayTwo" mb={[4]}>Support and Contribute</Heading>
                                <Typography as="p" variant="body" mb={[6]}>You can support Workout Plan Bot in many different ways to help us become a better workout tracker app. Most of them don’t require you to spend any money and all of them contribute in making Workout Plan Bot the best workout tracker app.</Typography>
                                <Grid>
                                    <GridItem width={{default: 1/1, md: 1/2}}>
                                        <Heading as="h3" variant="displayThree" mb={[2]}>Write App Store Reviews</Heading>
                                        <Typography as="p" variant="body" mb={[6]}>Give us a 5-star rating in the <a href="https://apps.apple.com/gb/app/workout-plan-bot-workout-log/id1538317859">App Store</a> and help us to significantly increase the visibility of the App which in turn contributes in adding new workout tracking features to improve the App.</Typography>
                                    </GridItem>
                                    <GridItem width={{default: 1/1, md: 1/2}}>
                                        <Heading as="h3" variant="displayThree" mb={[2]}>Purchase Premium</Heading>
                                        <Typography as="p" variant="body" mb={[6]}>Unlock additional workout tracker functionality within the app. This helps pay the bills and supports the continued development of Workout Plan Bot.</Typography>
                                    </GridItem>
                                </Grid>
                            </Box>
                        </GridItem>
                    </Grid>
                </GridWrap>
            </SegmentOne>
        </Layout>
    );
}

export default Index;
