import React from 'react'

export const AppleHealth = () => {
    return (
        <svg width="206" height="57" viewBox="0 0 206 57" fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation" alt="Apple Health Logo">
            <path d="M192.183 0H13.5919C13.0689 0 12.5526 0 12.0312 0.00335156C11.5954 0.00670312 11.1612 0.015082 10.722 0.0217851C9.77318 0.0452461 8.81262 0.102223 7.86548 0.273152C6.91163 0.44073 6.02484 0.725613 5.15648 1.16634C4.30322 1.60372 3.52371 2.17181 2.84981 2.84548C2.16586 3.52081 1.60092 4.3034 1.16842 5.15638C0.724188 6.02276 0.444235 6.91427 0.276599 7.86779C0.105611 8.81125 0.0452617 9.76645 0.0217927 10.7216C0.0083818 11.159 0.00670544 11.5964 0 12.0338C0 12.5499 0 13.0677 0 13.5889V43.4144C0 43.9406 0 44.4551 0 44.9729C0.00670544 45.4153 0.0083818 45.8443 0.0217927 46.2867C0.0469381 47.2419 0.105611 48.1954 0.276599 49.1389C0.442559 50.0941 0.724188 50.989 1.16675 51.852C1.59925 52.7016 2.16418 53.4825 2.84646 54.1528C3.52036 54.8332 4.29986 55.3996 5.15313 55.832C6.02149 56.2777 6.90828 56.5593 7.86213 56.7302C8.80927 56.8995 9.76983 56.9564 10.7186 56.9816C11.1595 56.9916 11.592 56.9967 12.0279 56.9967C12.5492 57 13.0656 57 13.5886 57H192.18C192.693 57 193.212 57 193.725 56.9967C194.159 56.9967 194.605 56.9916 195.039 56.9816C195.995 56.9548 196.952 56.8995 197.891 56.7302C198.846 56.5576 199.733 56.2761 200.612 55.832C201.463 55.3996 202.243 54.8332 202.917 54.1528C203.596 53.4842 204.161 52.7016 204.601 51.852C205.039 50.9873 205.322 50.0924 205.483 49.1389C205.659 48.1954 205.715 47.2403 205.748 46.2867C205.753 45.8443 205.753 45.4153 205.753 44.9729C205.765 44.4551 205.765 43.9406 205.765 43.4144V13.5889C205.765 13.0677 205.765 12.5499 205.753 12.0338C205.753 11.5964 205.753 11.159 205.748 10.7216C205.715 9.76645 205.659 8.81293 205.483 7.86947C205.322 6.91427 205.037 6.02443 204.601 5.15638C204.162 4.3034 203.596 3.52081 202.917 2.84548C202.243 2.17014 201.463 1.60205 200.612 1.16634C199.735 0.725613 198.846 0.439054 197.891 0.273152C196.952 0.103898 195.995 0.0452461 195.039 0.0217851C194.605 0.015082 194.159 0.00670312 193.725 0.00335156C193.216 0 192.696 0 192.183 0Z" fill="black"/>
            <path d="M12.038 55.7566C11.6038 55.7566 11.1797 55.7515 10.7489 55.7415C9.95262 55.7197 9.00715 55.6744 8.08347 55.5085C7.21344 55.351 6.44064 55.0946 5.72148 54.7276C4.97886 54.3506 4.30999 53.8629 3.72997 53.2781C3.13989 52.6999 2.65374 52.0346 2.27489 51.2872C1.90609 50.5683 1.65296 49.7958 1.50041 48.9261C1.32607 47.9675 1.28248 46.9939 1.26236 46.2549C1.25398 45.9549 1.24225 44.9545 1.24225 44.9545V12.0338C1.24225 12.0338 1.25398 11.0484 1.26236 10.7585C1.2808 10.0111 1.32607 9.03914 1.49873 8.09065C1.65128 7.2159 1.90441 6.44504 2.27321 5.72277C2.65542 4.97538 3.14156 4.30674 3.72326 3.73027C4.31334 3.1404 4.98388 2.65107 5.72316 2.27234C6.45405 1.902 7.22685 1.64728 8.08012 1.49813C9.04068 1.32553 10.013 1.28363 10.7539 1.26352L12.0397 1.24677H193.722L195.023 1.2652C195.754 1.28363 196.726 1.3272 197.671 1.49646C198.53 1.64728 199.308 1.902 200.054 2.27737C200.784 2.65107 201.453 3.1404 202.037 3.72524C202.62 4.30339 203.108 4.97705 203.495 5.72613C203.862 6.45509 204.114 7.22595 204.258 8.07557C204.424 8.97379 204.476 9.89714 204.506 10.7652C204.509 11.1691 204.509 11.6031 204.509 12.0338C204.521 12.5683 204.521 13.0761 204.521 13.5889V43.4144C204.521 43.9322 204.521 44.4367 204.509 44.9461C204.509 45.4103 204.509 45.8343 204.504 46.2716C204.476 47.1112 204.424 48.0329 204.261 48.9127C204.114 49.7874 203.862 50.5583 203.492 51.2923C203.108 52.033 202.618 52.7016 202.043 53.2663C201.455 53.8612 200.786 54.3472 200.049 54.7243C199.309 55.0996 198.555 55.3493 197.671 55.5085C196.758 55.6744 195.812 55.718 195.006 55.7415C194.589 55.7515 194.151 55.7566 193.727 55.7566L192.181 55.7599L12.038 55.7566Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.7815 44.3512C27.8628 44.3512 22.4968 41.4521 17.4744 36.7046C13.4897 32.9375 10.6064 27.399 10.6064 22.4119C10.6064 17.8974 14.395 12.6505 20.512 12.6505C26.4731 12.6505 27.784 15.8579 28.7832 15.8579C29.5811 15.8579 31.1653 12.6505 37.0543 12.6505C43.5184 12.6505 46.9599 18.4185 46.9599 22.4119C46.9599 27.3973 44.2929 32.733 40.0919 36.7046C35.1969 41.3298 29.7001 44.3512 28.7815 44.3512Z" fill="url(#paint0_linear)"/>
            <path d="M57.8612 21.026H56.614L54.3257 12.5399H55.6953L57.2543 19.0385H57.3365L59.1067 12.5399H60.3305L62.1124 19.0385H62.1946L63.7469 12.5399H65.1182L62.8232 21.026H61.581L59.7622 14.7971H59.68L57.8612 21.026Z" fill="black"/>
            <path d="M65.8156 17.8203C65.8156 15.7557 66.9689 14.4972 68.8397 14.4972C70.7055 14.4972 71.8588 15.7557 71.8588 17.8203C71.8588 19.8966 70.7105 21.1484 68.8397 21.1484C66.9639 21.15 65.8156 19.8966 65.8156 17.8203ZM70.558 17.8203C70.558 16.4327 69.9344 15.6216 68.8397 15.6216C67.74 15.6216 67.1231 16.4327 67.1231 17.8203C67.1231 19.2195 67.74 20.0256 68.8397 20.0256C69.9344 20.0256 70.558 19.2145 70.558 17.8203Z" fill="black"/>
            <path d="M73.5704 14.6212H74.7891V15.6032H74.883C75.0657 14.9748 75.7781 14.5156 76.613 14.5156C76.8007 14.5156 77.0421 14.534 77.1779 14.5692V15.816C77.0723 15.7808 76.6951 15.7389 76.4722 15.7389C75.5133 15.7389 74.836 16.3455 74.836 17.2438V21.026H73.5704V14.6212Z" fill="black"/>
            <path d="M82.549 14.6212H84.0426L81.4074 17.3678L84.2203 21.026H82.6664L80.467 18.2023L79.9087 18.7319V21.026H78.6448V12.1209H79.9087V17.2907H80.0093L82.549 14.6212Z" fill="black"/>
            <path d="M87.6652 14.4972C89.1069 14.4972 90.0473 15.1675 90.1714 16.297H88.9594C88.842 15.8261 88.3827 15.5278 87.6652 15.5278C86.9595 15.5278 86.4231 15.863 86.4231 16.3623C86.4231 16.7444 86.7466 16.9857 87.4406 17.1449L88.5051 17.3913C89.7221 17.6728 90.2938 18.1973 90.2938 19.1374C90.2938 20.3423 89.1706 21.1484 87.6401 21.1484C86.1163 21.1484 85.1222 20.4596 85.0099 19.3251H86.2755C86.4348 19.8195 86.9042 20.1245 87.6703 20.1245C88.4582 20.1245 89.0181 19.7725 89.0181 19.2598C89.0181 18.8777 88.718 18.6313 88.076 18.4772L86.9578 18.2191C85.7408 17.9309 85.1759 17.3829 85.1759 16.431C85.1775 15.2965 86.2185 14.4972 87.6652 14.4972Z" fill="black"/>
            <path d="M102.077 21.026H100.764L99.4417 16.3087H99.3394L98.0218 21.0243H96.721L94.9557 14.6195H96.2382L97.3865 19.5078H97.4803L98.798 14.6195H100.01L101.328 19.5078H101.428L102.57 14.6195H103.835L102.077 21.026Z" fill="black"/>
            <path d="M105.175 12.7275C105.175 12.2868 105.522 11.9517 105.993 11.9517C106.462 11.9517 106.809 12.2868 106.809 12.7275C106.809 13.1632 106.462 13.4984 105.993 13.4984C105.522 13.4984 105.175 13.1632 105.175 12.7275ZM105.363 14.6212H106.622V21.026H105.363V14.6212Z" fill="black"/>
            <path d="M110.378 13.0275V14.6513H111.768V15.7154H110.378V19.0084C110.378 19.6787 110.655 19.9719 111.285 19.9719C111.478 19.9719 111.592 19.9602 111.768 19.9418V20.9942C111.562 21.0293 111.325 21.0595 111.079 21.0595C109.672 21.0595 109.114 20.5652 109.114 19.3301V15.7154H108.097V14.6513H109.114V13.0275H110.378Z" fill="black"/>
            <path d="M113.505 12.1209H114.757V15.6501H114.857C115.171 14.9329 115.847 14.5022 116.81 14.5022C118.175 14.5022 119.017 15.3669 119.017 16.8902V21.0243H117.751V17.2019C117.751 16.178 117.275 15.6618 116.381 15.6618C115.345 15.6618 114.769 16.3154 114.769 17.2857V21.026H113.505V12.1209Z" fill="black"/>
            <path d="M65.7418 39.6423H58.9894L57.3684 44.4283H54.5068L60.9038 26.717H63.8743L70.2713 44.4283H67.3612L65.7418 39.6423ZM59.6885 37.4336H65.0411L62.4008 29.6647H62.3271L59.6885 37.4336Z" fill="black"/>
            <path d="M84.0728 37.9732C84.0728 41.9867 81.9238 44.564 78.6833 44.564C76.841 44.564 75.3809 43.7412 74.6198 42.3051H74.5578V48.6999H71.9058V31.5181H74.4723V33.6664H74.5209C75.2569 32.2789 76.8293 31.384 78.6347 31.384C81.912 31.3824 84.0728 33.9714 84.0728 37.9732ZM81.3471 37.9732C81.3471 35.359 79.9959 33.6413 77.934 33.6413C75.909 33.6413 74.5461 35.3959 74.5461 37.9732C74.5461 40.5757 75.909 42.3185 77.934 42.3185C79.9959 42.3185 81.3471 40.6126 81.3471 37.9732Z" fill="black"/>
            <path d="M98.2783 37.9732C98.2783 41.9867 96.1292 44.564 92.8888 44.564C91.0465 44.564 89.5864 43.7412 88.8253 42.3051H88.7633V48.6999H86.1113V31.5181H88.6778V33.6664H88.7264C89.4623 32.2789 91.0347 31.384 92.8402 31.384C96.1175 31.3824 98.2783 33.9714 98.2783 37.9732ZM95.5525 37.9732C95.5525 35.359 94.2014 33.6413 92.1395 33.6413C90.1144 33.6413 88.7515 35.3959 88.7515 37.9732C88.7515 40.5757 90.1144 42.3185 92.1395 42.3185C94.2014 42.3185 95.5525 40.6126 95.5525 37.9732Z" fill="black"/>
            <path d="M100.414 26.7186H103.066V44.43H100.414V26.7186Z" fill="black"/>
            <path d="M116.866 40.6377C116.51 42.9821 114.227 44.5892 111.305 44.5892C107.549 44.5892 105.215 42.0738 105.215 38.0352C105.215 33.9848 107.56 31.3589 111.195 31.3589C114.767 31.3589 117.013 33.8139 117.013 37.7285V38.6368H107.892V38.796C107.892 41.0047 109.28 42.4542 111.366 42.4542C112.839 42.4542 113.994 41.7554 114.35 40.6377H116.866ZM107.906 36.7834H114.363C114.303 34.8076 113.037 33.5056 111.196 33.5056C109.366 33.5056 108.04 34.8311 107.906 36.7834Z" fill="black"/>
            <path d="M136.328 44.43V36.5873H127.229V44.43H124.478V26.7186H127.229V34.206H136.328V26.7186H139.079V44.43H136.328Z" fill="black"/>
            <path d="M153.089 40.6377C152.732 42.9821 150.448 44.5892 147.526 44.5892C143.77 44.5892 141.436 42.0738 141.436 38.0352C141.436 33.9848 143.78 31.3589 147.414 31.3589C150.988 31.3589 153.236 33.8139 153.236 37.7285V38.6368H144.113V38.796C144.113 41.0047 145.5 42.4542 147.589 42.4542C149.062 42.4542 150.214 41.7554 150.571 40.6377H153.089ZM144.123 36.7834H150.584C150.522 34.8076 149.258 33.5056 147.414 33.5056C145.585 33.5056 144.259 34.8311 144.123 36.7834Z" fill="black"/>
            <path d="M154.768 40.76C154.768 38.5011 156.5 37.1387 159.569 36.9543L163.105 36.7583V35.7762C163.105 34.3401 162.161 33.5307 160.552 33.5307C159.078 33.5307 158.047 34.2295 157.839 35.3472H155.358C155.434 33.0146 157.606 31.3589 160.627 31.3589C163.708 31.3589 165.748 33.0397 165.748 35.5819V44.4316H163.205V42.3084H163.143C162.394 43.7446 160.76 44.6529 159.066 44.6529C156.535 44.6512 154.768 43.0793 154.768 40.76ZM163.105 39.5937V38.5999L159.925 38.796C158.342 38.8949 157.445 39.5819 157.445 40.6863C157.445 41.8158 158.379 42.5514 159.802 42.5514C161.656 42.5514 163.105 41.2745 163.105 39.5937Z" fill="black"/>
            <path d="M168.407 26.7186H171.059V44.43H168.407V26.7186Z" fill="black"/>
            <path d="M177.225 28.4615V31.5181H179.681V33.6162H177.225V40.7349C177.225 41.8392 177.716 42.3554 178.797 42.3554C179.065 42.3554 179.496 42.3185 179.669 42.2934V44.3797C179.374 44.4534 178.785 44.502 178.195 44.502C175.579 44.502 174.561 43.52 174.561 41.0164V33.6162H172.682V31.5181H174.561V28.4615H177.225Z" fill="black"/>
            <path d="M181.954 26.7186H184.579V33.715H184.641C185.414 32.1683 186.753 31.3706 188.73 31.3706C191.564 31.3706 193.259 33.2609 193.259 36.1818V44.43H190.595V36.7096C190.595 34.8076 189.637 33.653 187.769 33.653C185.804 33.653 184.603 35.0037 184.603 37.0532V44.43H181.951V26.7186H181.954Z" fill="black"/>
            <defs>
                <linearGradient id="paint0_linear" x1="28.7815" y1="12.6506" x2="28.7815" y2="44.3517" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF61AD"/>
                    <stop offset="1" stop-color="#FF2616"/>
                </linearGradient>
            </defs>
        </svg>
    )
}
